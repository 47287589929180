import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { USER_UPDATE_PASSWORD_WITH_TOKEN } from '../Graphql/Mutations'
import { useMutation } from '@apollo/client'
import sideImage from '../Images/Homeprint-login.jpeg'
import handle_errors from '../Helpers/auth_errors'
import { useNavigate } from 'react-router-dom'


function ResetPassword(props) {
    /* 
       Pass the useFormik() hook initial form values and a submit function that will
       be called when the form is submitted
    */
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues:{
            userToken:'',
            newPassword: '',
            passwordConfirmation: '',
        },
        validationSchema: Yup.object({
            userToken: Yup.string().required('Required'),
            newPassword: Yup.string().required('Required'),
            passwordConfirmation: Yup.string().required('Required')
        }),
        onSubmit:values => {
            alert(JSON.stringify(values, null, 2))
        },
    })

    // useMutation hook. TODO: update with correct mutation
    const [userUpdatePasswordWithToken, { data, loading, error }] = useMutation(USER_UPDATE_PASSWORD_WITH_TOKEN)

    // Handles the submision of the form field and sends request to update password to server
    function handleSubmit(e) {
        e.preventDefault()
        // TODO:Update with the correct mutation
        // console.log(formik.values.userToken)
        let params = new URLSearchParams(window.location.search);
        let t = params.get('t');
        console.log(t)
        userUpdatePasswordWithToken({
            variables: {
                password: formik.values.newPassword,
                confirm_password: formik.values.passwordConfirmation,
                token: t,
            },
            onCompleted: (data) => {
                if(data.userUpdatePasswordWithToken.authenticatable){
                    // console.log('suc')
                    alert('Your password has successfully been reset.  You will now be navigated to login.')
                    navigate('/login')
                }
                // const res = JSON.stringify(data.userUpdatePasswordWithToken.credentials)
                // localStorage.setItem('credentials', res)
                // props.setLogged(true)
            }
        })
    }
    return (
        <>
            {/* wrapper */}
            <div className="font-int flex  h-screen flex-col bg-white lg:relative">
                {/* container of body and image */}
                <div className="flex max-w-full flex-grow flex-col md:flex-row">
                    {/* container for vertical alignment and large screens in 2 */}
                    <div className="md:w-1/2 md:self-center">
                        {/* container for horizontal alignment of text elements */}
                        <div className="mx-auto max-w-sm px-3 py-12 sm:pt-12 sm:pb-12 lg:py-16 2xl:max-w-lg">
                            {/* title */}
                            <h1 className="my-6 text-4xl font-bold tracking-tight text-gray-900 text-center sm:text-5xl sm:tracking-tight">
                                Reset password
                            </h1>
                            <p className="text-center text-base font-normal text-[#667085]
                                        mb-4
                            "> 
                                Please type the information below 
                            </p>
                            <form
                                className="max-w-sm md:max-w-md 2xl:max-w-lg"
                                onSubmit={handleSubmit}
                            >
                                {/* Verification Token */}
                                {/* <div className="flex flex-col py-2">
                                    <label
                                        htmlFor="userToken"
                                        className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                    >
                                        {' '}
                                        Verification code{' '}
                                    </label>
                                    <input
                                        id="token"
                                        type="text"
                                        name="userToken"
                                        value={formik.values.userToken}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                    />
                                    {formik.touched.userToken && formik.errors.userToken ? (
                                    <div className='mt-1 text-xs text-red-800'> {formik.errors.userToken} </div>
                                    ): null }
                                </div> */}
                               {/* New Password */}
                                <div className="mx-auto flex flex-col py-2">
                                    <label
                                        htmlFor="newPassword"
                                        className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                    >
                                        New password*
                                    </label>
                                    <input
                                        type="password"
                                        name="newPassword"
                                        id="newPassword"
                                        className="border-blue-gray-300 2xl:text-bases col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.newPassword}
                                    />
                                    { formik.touched.newPassword && formik.errors.newPassword ? (
                                        <div className='mt-1 text-xs text-red-800'>{formik.errors.newPassword}</div>
                                    ) : null }
                                </div>
                                {/* Password Confirmation */}
                                <div className="mx-auto flex flex-col py-2">
                                    <label
                                        htmlFor="passwordConfirmation"
                                        className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                    >
                                        Confirm password*
                                    </label>
                                    <input
                                        type="password"
                                        name="passwordConfirmation"
                                        id="passwordConfirmation"
                                        className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.passwordConfirmation}
                                    />
                                    {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                                        <div className='mt-1 text-xs text-red-800'> {formik.errors.passwordConfirmation} </div>
                                    ) : null }
                                </div>

                                {handle_errors(error)}
                                {/* Button's container */}
                                <div className="mx-auto mt-4 flex flex-col md:max-w-sm 2xl:max-w-lg">
                                    {/* 'Create account' button */}
                                    <button
                                        onClick={() => null
                                        }
                                        className="mb-3 btn-primary-lg 2xl:text-lg"
                                    >
                                        Reset password
                                    </button>
                                    {/* login button redirects to Login component */}
                                    <p className="text-center">
                                        Go back to
                                        <a
                                            className="ml-1 text-[#6941C6] hover:cursor-pointer"
                                            href="/login"
                                        >
                                            Log in
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* photo */}
                    <div className="hidden md:flex md:w-1/2">
                        <img
                            className="object-cover md:object-right"
                            src={sideImage}
                            alt="House at dusk, with warm lighting seen inside"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword